import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Slider from "react-slick-slider"
import SliderNextArrow from "../../components/template-partials/slider-components/slider-next-arrow"
import SliderPrevArrow from "../../components/template-partials/slider-components/slider-prev-arrow"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import ShareButtons from "../../components/template-partials/global-components/share-buttons"
import InteriorMenu from "../../components/template-partials/global-components/interior-menu"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"
import InteriorPageFooter from "../../components/template-partials/global-components/interior-page-footer"
import FancyImage from "../../components/template-partials/global-components/fancy-image"
import InlineInfocard from "../../components/template-partials/global-components/inline-infocard"
import VideoInfoCard from "../../components/template-partials/global-components/video-infocard"
import QuestionInfocard from "../../components/template-partials/global-components/question-infocard"
import AfterTreatmentSubmenu from "../../components/template-partials/after-treatment/after-treatment-submenu"

import { ExploreCard } from "../../components/template-partials/global-components/footer-cards"

import headingBg from "../../images/6.0-headerbg.jpg"
import headingRound from "../../images/after-treatment-nav-image.jpg"

import Tabs from "../../components/template-partials/global-components/tabs/tabs"

import {
  faExternalLinkAlt,
  faAngleRight, faLink,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import riskReturn from "../../images/6.0_riskreturnlow.png"
import followUpVisit from "../../images/6.0_followupvisit.png"
import therapyComplete from "../../images/6.0_therapycomplete.png"

import dietary from "../../images/6.1.2_dietary.png"
import hairRegrowth from "../../images/6.1.2_hairregrowth.png"

import emergencyCall from "../../images/6.1.3_emergencycall.png"
import limitSnack from "../../images/6.1.3_limitsnacks.png"
import limitActivity from "../../images/6.1.3_limitactivityv3.png"
import behaviorProblems from "../../images/6.1.3_behaviorproblems2.png"
import hearingAid from "../../images/6.1.3_hearingaidAsset6.png"
import medicationsNeeded from "../../images/6.1.3_medicationsneeded.png"
import immunization from "../../images/6.2.02_injection.png"

import hearing from "../../images/6.1.3_hearingaidAsset7.png"
import thyroid from "../../images/6.1.5_thyroid.png"
import reproductive from "../../images/6.1.5_reproductiveorgan.png"
import pancreas from "../../images/6.1.5_diabetes_pancreas.png"
import bones from "../../images/6.1.5_bone.png"
import growthHormones from "../../images/6.1.5_growthhormone.png"
import kidney from "../../images/6.1.5_kidney.png"
import heart from "../../images/6.1.5_heart.png"
import lungs from "../../images/6.1.5_v2lungs.png"

import multiDiscFollowUp from "../../images/6.1-multidisc-followup.jpg"
import followUpLong from "../../images/6.1-followup-long.jpg"
import longTermTrans from "../../images/6.1-long-term-trans.jpg"
import anniversary from "../../images/6.1-anniversary.jpg"
import talking from "../../images/6.1-talking.jpg"
import returnSchool from "../../images/6.1-return-school.jpg"
import learning from "../../images/6.1-learning.jpg"
import oneSixMonths from "../../images/6.1-16.jpg"
import followup from "../../images/6.1-followup.jpg"
import emotionalLate from "../../images/6.1-emotional-late.jpg"

import videoScreen from "../../images/tv-lime.png"
import videoScreenPink from "../../images/tv-pink.png"
import VideoPlayer from "../../components/VideoPlayer"

import zaraTakesOff from "../../images/Zara-takes-off.png"

//icon
//import linkIcon from ../../images/icon-link.svg
import Accordion from "../../components/template-partials/global-components/accordion/accordion"

//Cool! Export your sass Vars from a top line and use them in JS!
import * as SassVars from "../../components/variables.module.scss"
import InlineInfocardIcon from "../../components/template-partials/global-components/inline-infocardicon"

const AfterTreatmentWhatsNext = () => {
  const [active, setActive] = useState(1)
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: SliderNextArrow,
    prevArrow: SliderPrevArrow,
    responsive: [
      { breakpoint: 1000000, settings: "unslick" },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const scrollIntoViewWithOffset = (selector, offset) => {
    window.scrollTo({
      behavior: 'smooth',
      top:
        document.querySelector(selector).getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        offset,
    })
  }

  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
          e.preventDefault();
          //console.log(document.querySelector(this.getAttribute('href')).getAttribute('id'));
          scrollIntoViewWithOffset('#'+document.querySelector(this.getAttribute('href')).getAttribute('id'), 40);

      });
    });
  }, [])

  return (
    <Layout>
      <SEO
        title="After NeuroblastomaTreatment | What’s Next"
        description="Making the transition from treatment to follow-up care can bring mixed emotions and changes to routines. After your child’s neuroblastoma treatment ends, their healthcare team may provide guidance about what to expect during the stages of follow-up care."
      />

      <InteriorPageHeader
        backgroundImage={headingBg}
        roundImage={headingRound}
        alt="What Happens Next and When"
      >
        <h1 className="section__heading section__heading--green h1">
          What Happens Next and When
        </h1>
      </InteriorPageHeader>

      {/* style={{backgroundColor: SassVars.viridian}} */}

      <Section className="interior-page">
        <div className="row row--inner">
          <div className="columns small-12 large-4">
            <InteriorMenu title={`After Treatment`}>
              <AfterTreatmentSubmenu />
            </InteriorMenu>
          </div>
          <div className="columns">
            <div className="row">
              <div className="columns">
                <ShareButtons />
              </div>
            </div>
            <div className="row">
              <div className="columns">
                <p>
                  As treatment comes to an end, you and your family can start to
                  transition out of treatment mode and into more familiar
                  routines while the healthcare team continues to monitor your
                  child’s recovery. The end of your child’s neuroblastoma
                  treatment is a joyous milestone and something to be
                  celebrated. After months of therapy, you, your child, your
                  family, and loved ones should recognize and celebrate this
                  achievement.
                </p>
                <h3 className="h3 jost-med tc-viridian lg_mt-2">
                  Jump to a section below to learn more:
                </h3>
                <ul className="ul--standard jump-links lg_mt-1">
                  <li>
                    <a href="#finishing-treatment">
                      <FontAwesomeIcon icon={faLink} />Finishing treatment</a>
                  </li>
                  <li>
                    <a href="#six-months-after">
                      <FontAwesomeIcon icon={faLink} />1 to 6 months after treatment</a>
                  </li>
                  <li>
                    <a href="#twelve-months-after">
                      <FontAwesomeIcon icon={faLink} />6 to 12 months after treatment</a>
                  </li>
                  <li>
                    <a href="#beyond-one-year">
                      <FontAwesomeIcon icon={faLink} />Beyond 1 year after treatment</a>
                  </li>
                  <li>
                    <a href="#long-term-care">
                      <FontAwesomeIcon icon={faLink} />Long-term follow-up care</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row row--inner mt-2 md_mt-2 lg_mt-2">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">
              The Importance of After Treatment Follow-up
            </h3>
            <p>
              Hear from a pediatric oncologist and nurse practitioner about why
              long-term follow-up is important for your child after they
              complete their treatment journey.
            </p>
            <VideoPlayer
              embedId="761928402"
              title="The Importance of After Treatment Follow-up"
            />
          </div>
        </div>
      </Section>

      <Section style={{ backgroundColor: SassVars.paleBlue }}>
        <div className="row row--inner align-middle">
          <div className="columns">
            <h2 className="h2 tc-barney jost-med">
              Learn about the transition from treatment{" "}
            </h2>
            <p>
              <strong>
                Find answers below to common questions that caregivers have at
                each stage of the transition.
              </strong>
            </p>
          </div>
        </div>

        <div className="row row--inner">
          <div className="columns">
            <Accordion className="lg_mt-2 mt-2">
              <Accordion.Item isOpen="true">
                <Accordion.Drawer className="acc-sub-hd"><h3 className="tc-barney jost-med" id="finishing-treatment">Finishing treatment</h3><p>It’s okay to have uncertainties—here are some things to keep in mind that may help you with the transition after treatment ends.</p></Accordion.Drawer>
                <Accordion.Expanded>
                  <div className="columns small-12">
                    <div className="row row--inner">
                      <div className="columns">
                        <h4 className="h4 tc-viridian jost-med mt-2">
                          Feelings of uncertainty
                        </h4>
                        <p>
                          It is okay to have mixed feelings about the end of treatment.
                          Many parents and caregivers do. Even though you are thankful
                          that your child has finished treatment, you may feel uncertain
                          about the future or worried about your child's cancer
                          returning.
                        </p>
                        <p>
                          Fear of the cancer returning (
                          <Link
                            rel="glossary"
                            to="/resources/glossary/#relapse"
                          >
                            relapse
                          </Link>
                          ) is a common feeling among cancer survivors and caregivers of
                          children with neuroblastoma. It may help to remember that:{" "}
                        </p>
                      </div>
                    </div>
                    <div className="row row--inner lg_mt-4 align-middle">
                      <div className="columns small-12 large-4 text-center">
                        <img
                          src={therapyComplete}
                          style={{ maxWidth: `90px`, marginTop: '20px' }}
                          alt="Your child has completed treatment because the oncologist believes it is safe to end therapy"
                        />
                        <p className="lg_mt-2">
                          Your child has completed treatment because the oncologist
                          believes it is safe to end therapy
                        </p>
                      </div>
                      <div className="columns small-12 large-4 text-center mt-3">
                        <img
                          src={followUpVisit}
                          style={{ maxWidth: `90px` }}
                          alt="Your child has completed treatment because the oncologist believes it is safe to end therapy"
                        />
                        <p className="lg_mt-2">
                          Your child will have frequent follow-up visits to make sure
                          they are neuroblastoma-free
                        </p>
                      </div>
                      <div className="columns small-12 large-4 text-center mt-3">
                        <img
                          src={riskReturn}
                          style={{ maxWidth: `151px` }}
                          alt="Your child has completed treatment because the oncologist believes it is safe to end therapy"
                        />
                        <p className="lg_mt-2">
                          The chances of neuroblastoma returning decrease the longer
                          your child is off treatment
                        </p>
                      </div>
                    </div>
                    <div className="row row--inner lg_mt-4 mt-4">
                      <div className="columns small-12 large-4 large-text-left text-center">
                        <FancyImage
                          url={followup}
                          alt="As treatment ends, follow-up care begins"
                        />
                      </div>
                      <div className="columns mt-2">
                        <h4 className="h4 tc-viridian jost-med">
                          As treatment ends, follow-up care begins{" "}
                        </h4>
                        <p>
                          <Link
                            rel="glossary"
                            to="/resources/glossary/#follow-up-care"
                          >
                            Follow-up care
                          </Link>{" "}
                          is the care your child receives as their treatment ends, and
                          it will take place over an extended period of time (months to
                          years).
                        </p>
                        <p>
                          Your child's oncology team may provide a recommended schedule
                          of follow-up visits. During these visits, your child will
                          undergo labs, scans, and a physical exam, allowing the
                          oncologist to monitor for neuroblastoma and keep a watchful
                          eye on your child’s recovery. The adjustment period from
                          treatment into follow-up care takes some time. These follow-up
                          care visits can help you:
                        </p>
                        <ul className="ul--dotted">
                          <li>
                            Know that your child's recovery is going according to plan
                          </li>
                          <li>
                            Start to identify any short- and{" "}
                            <Link
                              rel="glossary"
                              to="/resources/glossary/#long-term-side-effect"
                            >
                              long-term effects
                            </Link>{" "}
                            of treatment
                          </li>
                          <li>
                            Monitor for{" "}
                            <Link
                              rel="glossary"
                              to="/resources/glossary/#late-effect"
                            >
                              late effects
                            </Link>{" "}
                            (a health problem that appears months or years after cancer
                            therapy has ended)
                          </li>
                          <li>Discuss any questions or concerns you have</li>
                        </ul>
                      </div>
                    </div>
                    <div className="row row--inner lg_mt-2 mt-2">
                      <div className="columns">
                        <p>
                          <Link to="/what-is-neuroblastoma/how-is-it-diagnosed/">
                            Review the labs, scans, and exams your child’s healthcare
                            team will run to monitor your child’s recovery.
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </Accordion.Expanded>
              </Accordion.Item>
              <Accordion.Item>
                <Accordion.Drawer className="acc-sub-hd"><h3 className="tc-barney jost-med" id="six-months-after">1 to 6 months after treatment</h3><p>Transitioning your child's care, managing fever and illness, central line removal, and more</p></Accordion.Drawer>
                <Accordion.Expanded>
                  <div className="columns small-12">
                    <div className="row row--inner">
                      <div className="columns">
                        <h4 className="h4 tc-viridian jost-med mt-2">
                          Location of follow-up visits
                        </h4>
                        <p>
                          After treatment ends, it is most common for your child’s care
                          to occur in the{" "}
                          <Link
                            rel="glossary"
                            to="/resources/glossary/#outpatient"
                          >
                            outpatient
                          </Link>{" "}
                          clinic and not the hospital.{" "}
                        </p>
                        <h4 className="h4 tc-viridian jost-med mt-2">
                          Fevers and illness{" "}
                        </h4>
                        <p>
                          Once treatment ends, you should have a plan in place with
                          direction from your child's oncology team in case your child
                          develops a{" "}
                          <Link rel="glossary" to="/resources/glossary/#fever">
                            fever
                          </Link>{" "}
                          or illness. This may require giving your child antibiotics at
                          the hospital where they were treated.
                        </p>
                        <p>
                          Your child may not be allowed to see their pediatrician for
                          fever or illness until their blood counts have returned to
                          normal and their{" "}
                          <Link
                            rel="glossary"
                            to="/resources/glossary/#central-line-or-cl"
                          >
                            central line
                          </Link>{" "}
                          has been removed.
                        </p>
                      </div>
                      <div className="columns large-4 text-center large-text-right small-12">
                        <FancyImage
                          url={oneSixMonths}
                          alt="1 to 6 months after treatment"
                        />
                      </div>
                    </div>
                    <div className="row row--inner lg_mt-2 mt-2 align-middle">
                      <div className="columns">
                        <h4 className="h4 tc-viridian jost-med mt-2">
                          Central line removal{" "}
                        </h4>
                        <p>
                          Removal of your child’s central line will usually happen
                          within a month after treatment. This requires a minor surgical
                          procedure and may be a little uncomfortable.
                        </p>
                      </div>
                    </div>
                    <div className="row row--inner lg_mt-2 mt-2 align-middle">
                      <div className="columns small-12 large-shrink">
                        <img
                          src={hairRegrowth}
                          alt="After neuroblastoma treatment, hair regrowth is most common within 3 to 6 months"
                        />
                      </div>
                      <div className="columns">
                        <h4 className="h4 tc-viridian jost-med mt-2">Hair regrowth</h4>
                        <p>
                          Hair loss is often a side effect of your child’s{" "}
                          <Link
                            rel="glossary"
                            to="/resources/glossary/#chemotherapy"
                          >
                            chemotherapy
                          </Link>{" "}
                          or radiation therapy. It may take several weeks after the
                          completion of treatment for hair to recover and begin growing
                          again. Hair regrowth is most common within 3 to 6 months after
                          treatment.{" "}
                        </p>
                      </div>
                    </div>
                    <div className="row row--inner lg_mt-2 mt-2">
                      <div className="columns">
                        <h4 className="h4 tc-viridian jost-med">
                          Dietary adjustments{" "}
                        </h4>
                        <p>
                          Your child may have preferred certain high-calorie and
                          high-sodium foods during their treatment. While this is often
                          encouraged on treatment, it is important to reintroduce a
                          balanced and nutritious diet to help them heal and grow.
                        </p>
                        <p>
                          A healthy diet includes a proper balance of protein,
                          carbohydrates, fats, vitamins, and minerals best obtained from
                          fresh foods. Reach out to your child's{" "}
                          <Link
                            rel="glossary"
                            to="/resources/glossary/#registered-dietitian"
                          >
                            registered dietitian
                          </Link>{" "}
                          to learn how you can help them return to making healthy
                          choices.
                        </p>
                      </div>
                      <div className="columns small-12 large-shrink mt-2">
                        <img
                          src={dietary}
                          alt="Reach out to your child’s registered dietician to learn how to adjust their diet after neuroblastoma treatment"
                        />
                      </div>
                    </div>
                    <div className="row row--inner lg_mt-2 mt-2">
                      <div className="columns">
                        <h4 className="h4 tc-viridian jost-med">
                          Activity restrictions
                        </h4>
                        <p>
                          It is important to talk to your healthcare team about any
                          limitations your child may have due to their specific
                          treatment. In general, you should encourage your child to
                          return to normal activities as soon as they feel strong
                          enough.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </Accordion.Expanded>
              </Accordion.Item>
              <Accordion.Item>
                <Accordion.Drawer className="acc-sub-hd"><h3 className="tc-barney jost-med" id="twelve-months-after">6 to 12 months after treatment</h3><p>Planning your child's return to school, including addressing learning challenges, and immunizations</p></Accordion.Drawer>
                <Accordion.Expanded>
                  <div className="columns small-12">
                    <div className="row row--inner">
                      <div className="columns">
                        <h4 className="h4 tc-viridian jost-med mt-2">
                          Returning to school
                        </h4>
                        <p>
                          Your child may have been unable to attend school consistently
                          during treatment. Going back to school should be a priority
                          because it provides a sense of normalcy and is an important
                          part of their overall development. Speak to your healthcare
                          team about your child’s readiness, as this often depends on
                          their blood count and immunization record.
                        </p>
                        <p>
                          It is important to begin planning your child’s return to
                          school as early as possible. This may involve conversations
                          with your child’s healthcare team, school administration,
                          school nurse, school psychologist, and teachers.
                        </p>
                        <p>It is important to communicate:</p>
                      </div>
                      <div className="columns large-4 text-center large-text-right small-12">
                        <FancyImage url={returnSchool} alt="Returning to school" />
                      </div>
                    </div>
                    <div className="row row--inner lg_mt-2 mt-2 mb-2">
                      <div className="columns">
                          <Slider
                            className="grid-slider grid-slider--large-3"
                            {...settings}
                          >
                            <div className="text-center">
                              <img
                                src={medicationsNeeded}
                                alt="title"
                                style={{ maxWidth: `76px` }}
                              />
                              <p className="mt-2">
                                Medications your child needs to take and how to give
                                them
                              </p>
                            </div>
                            <div className="text-center">
                              <img
                                src={hearingAid}
                                alt="title"
                                style={{ maxWidth: `86px` }}
                              />
                              <p className="mt-2 lg_mt-2">
                                Special devices they may have
                              </p>
                            </div>
                            <div className="text-center">
                              <img
                                src={behaviorProblems}
                                alt="title"
                                style={{ maxWidth: `55px` }}
                              />
                              <p className="mt-2 lg_mt-2">
                                Problems to watch for and report to you
                              </p>
                            </div>
                            <div className="text-center">
                              <img
                                src={limitActivity}
                                alt=""
                                style={{ maxWidth: `121px` }}
                              />
                              <p className="mt-2">
                                Limitations to your child’s activity
                              </p>
                            </div>
                            <div className="text-center">
                              <img
                                src={limitSnack}
                                alt=""
                                style={{ maxWidth: `118px` }}
                              />
                              <p className="mt-2 lg_mt-2">
                                Special accommodations for physical needs (including
                                extra breaks or snacks)
                              </p>
                            </div>
                            <div className="text-center">
                              <img
                                src={emergencyCall}
                                alt=""
                                style={{ width: `70px`, marginTop: `25px` }}
                              />
                              <p className="mt-2 lg_mt-2">
                                <Link to="/resources/downloadable-resources/#downloadables">
                                  Who to call with questions and emergencies{" "}
                                  <FontAwesomeIcon icon={faAngleRight} />
                                </Link>
                              </p>
                            </div>
                          </Slider>
                      </div>
                    </div>
                    <div className="row row--inner lg_mt-4">
                      <div className="columns">
                        <h4 className="h4 tc-viridian jost-med">
                          Addressing learning challenges
                        </h4>
                        <p>
                          Because treatment for neuroblastoma will sometimes include
                          therapy targeting the central{" "}
                          <Link
                            rel="glossary"
                            to="/resources/glossary/#nervous-system"
                          >
                            nervous system
                          </Link>
                          , your child may have trouble concentrating in school. While
                          learning challenges are not always apparent right away, they
                          can arise years after treatment. Learning challenges should be
                          discussed with your child’s healthcare team, the school
                          psychologist, and the school team. A <strong>504 plan</strong>{" "}
                          or <strong>Individualized Educational Plan (IEP)</strong> may
                          be utilized to ensure that your child’s specific needs are
                          addressed over time, and that a learning environment that
                          fosters academic success is available.{" "}
                        </p>
                      </div>
                      <div className="columns small-12 text-center large-4 large-text-right">
                        <FancyImage
                          url={learning}
                          alt="Addressing learning challenges"
                        />
                      </div>
                    </div>
                    <div className="row row--inner lg_mt-2 mt-2">
                      <div className="columns">
                        <p>
                          Some children feel excited about returning to school, while
                          others may feel nervous. These are both understandable
                          reactions. The transition back to school can be challenging
                          for you, as well. Remaining in close contact with your child’s
                          oncology and school teams helps ensure that you and your child
                          will have adequate support throughout this transition.
                        </p>
                      </div>
                    </div>
                    <div className="row row--inner lg_mt-3 mt-2 align-middle">
                      <div className="columns small-12 large-4 text-center">
                        <img
                          src={immunization}
                          style={{ maxWidth: `204px` }}
                          alt="Immunizations"
                        />
                      </div>
                      <div className="columns mt-2">
                        <h4 className="h4 tc-viridian jost-med">Immunizations</h4>
                        <p>
                          In most cases, there is no need to repeat immunizations.
                          However, your child's healthcare team will test to see if
                          boosters are needed.
                        </p>
                        <p>
                          Your child might need to receive immunizations for the first
                          time if their schedule was interrupted by treatment.
                          Typically, children can start to catch up on these
                          immunizations 6 to 12 months after treatment. It is always
                          best to confirm the exact timing with your child's oncology
                          team before speaking to your pediatrician.
                        </p>
                      </div>
                    </div>
                  </div>
                </Accordion.Expanded>
              </Accordion.Item>
              <Accordion.Item>
                <Accordion.Drawer className="acc-sub-hd"><h3 className="tc-barney jost-med" id="beyond-one-year">Beyond 1 year after treatment</h3><p>Keeping your child informed and transitioning to long-term follow-up care</p></Accordion.Drawer>
                <Accordion.Expanded>
                  <div className="columns small-12">
                    <div className="row row--inner">
                      <div className="columns">
                        <h4 className="h4 tc-viridian jost-med mt-2">
                          Talking to your child about neuroblastoma
                        </h4>
                        <p>
                          When neuroblastoma was diagnosed, it is likely that your child
                          was very young, and they might not remember much about the
                          early stages of treatment. Gradually sharing the details of
                          what your child went through and teaching them about their
                          illness will empower them to become their own advocate. This
                          will also prepare them to take responsibility for medication
                          administration, potential{" "}
                          <Link
                            rel="glossary"
                            to="/resources/glossary/#long-term-side-effect"
                          >
                            long-term side effect
                          </Link>{" "}
                          management, and, ultimately, a transition to adult care.
                        </p>
                      </div>
                      <div className="columns large-4 small-12 text-center large-text-right mt-2">
                        <FancyImage
                          url={talking}
                          alt="Talking to your child about neuroblastoma"
                        />
                      </div>
                    </div>
                    <div className="row row--inner align-middle lg_mt-2 mt-2">
                      <div className="columns">
                        <p>
                          Starting conversations with your child about their
                          neuroblastoma will encourage them to ask questions and
                          understand the importance of staying informed about their
                          health and past illness. It can also help them realize why
                          follow-up care and clinic visits are so important as they
                          grow.
                        </p>
                      </div>
                    </div>
                    <div className="row row--inner align-middle lg_mt-4 mt-4">
                      <div className="columns large-4 small-12 text-center large-text-right">
                        <FancyImage alt="Anniversaries" url={anniversary} />
                      </div>
                      <div className="columns mt-2">
                        <h4 className="h4 tc-viridian jost-med">Anniversaries</h4>
                        <p>
                          Diagnosis or end-of-treatment anniversaries can bring up mixed
                          feelings for parents and caregivers. Speaking to other parents
                          and caregivers who are going through similar life experiences
                          may be helpful.{" "}
                          <Link to="/resources/support-groups/">
                            Find a neuroblastoma support group in your area
                          </Link>.{" "}
                        </p>
                      </div>
                    </div>
                    <div className="row row--inner lg_mt-4 mt-4">
                      <div className="columns">
                        <h4 className="h4 tc-viridian jost-med">
                          Transitioning to long-term follow-up care
                        </h4>
                        <p>
                          The transition from a pediatric oncology team to a{" "}
                          <Link
                            rel="glossary"
                            to="/resources/glossary/#long-term-follow-up-care"
                          >
                            long-term follow-up
                          </Link>{" "}
                          clinic varies depending on where your child received
                          treatment, and their personal recovery journey. This change
                          can happen anywhere from 2 to 5 years after treatment.
                        </p>
                        <p>
                          In some cases, the hospital where your child was treated will
                          care for them indefinitely. In other instances, they may
                          transition to a clinic for adult survivors of childhood
                          cancer.{" "}
                        </p>
                        <p>
                          Your child’s primary care provider (eg, pediatrician) will
                          also be an important part of this team as your contact for
                          issues unrelated to cancer. Your oncology providers/long-term
                          follow-up providers will notify them of your child’s progress
                          along the way.
                        </p>
                      </div>
                      <div className="columns large-4 small-12 text-center large-text-right">
                        <FancyImage
                          alt="Transitioning to long-term follow-up care"
                          url={longTermTrans}
                        />
                      </div>
                    </div>
                  </div>
                </Accordion.Expanded>
              </Accordion.Item>
              <Accordion.Item>
                <Accordion.Drawer className="acc-sub-hd"><h3 className="tc-barney jost-med" id="long-term-care">Long-term follow-up care</h3><p>Outlining the long-term care your child can expect, 2-5 years after treatment is complete</p></Accordion.Drawer>
                <Accordion.Expanded>
                  <div className="columns small-12">
                    <div className="row row--inner">
                      <div className="columns">
                        <p>
                          As follow-up care visits start to occur less often, and the
                          focus of appointments becomes more about wellness and less
                          about oncology, your child may transition to what is known as{" "}
                          <Link
                            rel="glossary"
                            to="/resources/glossary/#long-term-follow-up-care"
                          >
                            long-term follow-up care
                          </Link>
                          . This transition will typically occur 2 to 5 years after
                          treatment is completed. As a survivor of neuroblastoma, your
                          child will be given lifelong care by specialized healthcare
                          providers. Their care may be provided in the setting of:{" "}
                        </p>
                        <ul className="ul--dotted">
                          <li>The hospital where they were treated</li>
                          <li>
                            A specialized pediatric cancer long-term follow-up program
                          </li>
                          <li>
                            A specialized long-term follow-up program housed in an adult
                            hospital facility
                          </li>
                          <li>
                            A local primary care provider who works in partnership with
                            your child’s oncology team
                          </li>
                        </ul>
                      </div>
                      <div className="columns large-4 small-12 text-center large-text-right">
                        <FancyImage alt="Long-term follow-up care" url={followUpLong} />
                      </div>
                    </div>
                    <InlineInfocard
                      className="lg_mt-2 mt-2"
                      text="Ask your child’s healthcare team for their recommendations on long-term follow-up care settings near you."
                    />
                    <div className="row row--inner lg_mt-4 mt-2">
                      <div className="columns large-4 small-12 text-center large-text-left">
                        <FancyImage
                          alt="Multi-disciplinary follow-up"
                          url={multiDiscFollowUp}
                        />
                      </div>
                      <div className="columns mt-2">
                        <h3 className="h2 tc-barney jost-med">
                          Multi-disciplinary follow-up
                        </h3>
                        <p>
                          Cancer treatment can have an effect on the entire body. Your
                          child may be dealing with{" "}
                          <Link
                            rel="glossary"
                            to="/resources/glossary/#long-term-side-effect"
                          >
                            long-term effects
                          </Link>{" "}
                          from treatment and they will be monitored for potential{" "}
                          <Link
                            rel="glossary"
                            to="/resources/glossary/#late-effect"
                          >
                            late effects
                          </Link>{" "}
                          following guidelines from the Children’s Oncology Group.
                          Specialties may include but are not limited to:
                        </p>
                        <div className="row">
                          <div className="columns">
                            <ul className="ul--dotted mt-1">
                              <li>Audiology (hearing)</li>
                              <li>Cardiology (heart)</li>
                              <li>Endocrinology (hormones)</li>
                              <li>Fertility</li>
                            </ul>
                          </div>
                          <div className="columns mt-1">
                            <ul className="ul--dotted">
                              <li>Nutrition</li>
                              <li>Psychology</li>
                              <li>Pulmonology (lungs)</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row row--inner lg_mt-3 mt-3">
                      <div className="columns">
                        <h3 className="h2 tc-barney jost-med">
                          Requesting your child’s treatment summary
                        </h3>
                        <p>
                          A{" "}
                          <Link
                            rel="glossary"
                            to="/resources/glossary/#treatment-summary"
                          >
                            treatment summary
                          </Link>{" "}
                          is a document that outlines the therapy given to your child
                          throughout treatment. It serves as a record of exposure, and
                          will be used to evaluate the risk of{" "}
                          <Link
                            rel="glossary"
                            to="/resources/glossary/#late-effect"
                          >
                            late effects
                          </Link>{" "}
                          and help{" "}
                          <Link
                            rel="glossary"
                            to="/resources/glossary/#long-term-follow-up-care"
                          >
                            long-term follow-up
                          </Link>{" "}
                          providers tailor care for your child. Your long-term follow-up
                          team will review this document with you thoroughly to ensure
                          you understand its contents.
                        </p>
                        <p>
                          Treatment summaries often include the following information:
                        </p>
                        <div className="row">
                          <div className="columns lg_mt-1 mt-1">
                            <ul className="ul--dotted">
                              <li>Type of neuroblastoma (stage and risk group)</li>
                              <li>Type of therapy received</li>
                              <li>Location of radiation received, if relevant </li>
                              <li>
                                Doses of all medications and total amounts of radiation
                              </li>
                              <li>Dates of treatment</li>
                              <li>Results of all scans and tests</li>
                              <li>
                                Recorded side effects or complications during treatment
                              </li>
                            </ul>
                          </div>
                          <div className="columns lg_mt-1 mt-1">
                            <ul className="ul--dotted">
                              <li>
                                Recorded side effects or complications after treatment
                                (during follow-up care)
                              </li>
                              <li>
                                <Link
                                  rel="glossary"
                                  to="/resources/glossary/#clinical-trials"
                                >
                                  Clinical trial
                                </Link>{" "}
                                number and title (if your child participated in a
                                clinical trial)
                              </li>
                              <li>
                                Contact information for the hospital or clinic where
                                your child was treated
                              </li>
                              <li>
                                Contact information for the{" "}
                                <Link
                                  rel="glossary"
                                  to="/resources/glossary/#pediatric-oncologist-also-known-as-the-attending-physician"
                                >
                                  pediatric oncologist
                                </Link>
                              </li>
                              <li>
                                Surveillance (tests and scans) plan recommendations in
                                place
                              </li>
                              <li>Follow-up plan recommendations in place</li>
                            </ul>
                          </div>
                        </div>
                        <p className="lg_mt-2 mt-2">
                          Your child’s{" "}
                          <Link
                            rel="glossary"
                            to="/resources/glossary/#treatment-summary"
                          >
                            treatment summary
                          </Link>{" "}
                          should also be shared with healthcare providers outside of the
                          long-term follow-up team, including a primary care physician
                          or pediatrician, as well as emergency medical teams, if your
                          child should require emergency assistance.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </Accordion.Expanded>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </Section>

      <Section>
        <div className="row row--inner">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">
              Becoming their own advocate
            </h3>
            <p>
              As the years go by and your child grows older, it will be
              important for them to be aware of their unique medical needs.
              Teaching them over time about neuroblastoma and their specific
              journey will increase their awareness and readiness to take
              ownership of their health. Having an updated{" "}
              <Link
                rel="glossary"
                to="/resources/glossary/#treatment-summary"
              >
                treatment summary
              </Link>{" "}
              and care plan that they can share is key in helping them make sure
              they get the care they need.{" "}
            </p>
            <p>
              <a
                data-gtm-event-category="External Link"
                data-gtm-event-action="Click"
                data-gtm-event-label="Visit the Children’s Oncology Group"
                href="https://childrensoncologygroup.org/index.php/the-first-month-off-treatment"
                target="_blank"
                rel="noreferrer"
              >
                If you found these answers helpful, visit the Children’s
                Oncology Group for more information about what happens after
                treatment.{" "}
                <small>
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </small>
              </a>
            </p>
          </div>
        </div>
        <QuestionInfocard
          link="/resources/doctor-discussion-guide/"
          className="lg_mt-3 lg_mb-3"
          heading="What late effects of treatment are possible?"
          text="Find questions to ask your child’s doctor about care after treatment ends."
        />
        <InlineInfocardIcon
          className="lg_mt-2 mt-2"
          gtmCat="Internal Link"
          gtmAction="Click"
          gtmLabel="ZARA takes OFF"
          target="_self"
          link={`/resources/downloadable-resources/`}
          text="Use this informative family resource to help guide you and your child through the after-treatment phase of their recovery."
          img={zaraTakesOff}
        />
      </Section>

      <InteriorPageFooter>
        <ExploreCard
          single="true"
          title="Is your child planning on attending college?"
          lead="Learn about scholarship opportunities for neuroblastoma survivors."
          url="/resources/support-groups/#college"
        />
      </InteriorPageFooter>
    </Layout>
  )
}

export default AfterTreatmentWhatsNext
